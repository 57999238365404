<template>
  <div>
    <load-profile></load-profile>

    <!-- Table Container Card -->
    <b-card>
      <h3>{{ $t("general.announcement") }}</h3>
      <validation-observer ref="createForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="formFields" v-model="data" :key="`input-${key}`" />
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid"
                v-if="$can('create', resource)"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>

    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      v-model="params"
      @search="search"
      @reset="reset"
      :loading="loading"
      v-if="searchFields.length"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', resource)">
            <b-button variant="primary" :to="{ name: `create-${route}` }"
              >{{ $t("button.create") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(live)="data">
            <span v-if="data.item.tools">
              {{ data.item.tools.length }} {{ $t("field.live") }}
            </span>
          </template>
          <template #cell(status)="data">
            <b-badge pill variant="success" badge-glow v-if="data.item.status">
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="$can('update', resource)"
                :to="{
                  name: `update-${route}`,
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
          ref="pagination"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BModal,
  BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import Fields from "./annoucementFormInput";
import SearchInputs from "./searchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";

const LiveRepository = Repository.get("live");
const AnnocementRepository = Repository.get("annoucement");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BForm,
    BFormGroup,
    BInputGroup,
    BBadge,
    BModal,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NButtonDelete,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    vSelect,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    userData: function () {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
  },
  data() {
    return {
      key: 1,
      data: {
        textKh: null,
        textEn: null,
        status: true,
      },
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
      },
    };
  },
  mounted() {
    this.show();
    this.getData(this.params.page);
  },
  methods: {
    show() {
      AnnocementRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = {
              ...data,
            };
            this.key++;
          }
        })
        .catch((error) => {})
        .then(() => {});
    },
    submit() {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          AnnocementRepository.create(this.data)
            .then((response) => {})
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.createForm.setErrors(error.response?.data?.errors);
              }
            })
            .then(() => {});
        }
      });
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData(this.params.page);
    },
    search(searchText) {
      this.params.search = searchText;
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData(this.params.page);
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData(this.page);
    },
    getData() {
      this.loading = true;
      LiveRepository.index({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    destroy(item) {
      this.loading = true;

      LiveRepository.destroy({
        id: item.id,
      })
        .then((response) => {
          this.getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...TableFields];
    const formFields = [...Fields];
    const searchFields = [...SearchInputs];
    const resource = "bet-setting";
    const route = "live";

    return {
      fields,
      formFields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
