export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'nameKh',
    label: 'field.nameKh',
  },
  {
    key: 'live',
    label: 'field.live',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'actions',
    label: 'field.actions',
  },
];
