export default [
  {
    key: 'textKh',
    label: 'field.textKh',
    rules: 'required',
    type: 'textarea',
  },
  {
    key: 'textEn',
    label: 'field.textEn',
    rules: 'required',
    type: 'textarea',
  },
  {
    key: 'status',
    label: 'status.active',
    type: 'checkbox',
    cols: 'auto',
  },
]